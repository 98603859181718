import React from "react";
import "./styles/loading-screen.css";
import CompanyLogo from "../assets/global/LogoIcon.svg";

const CustomLoadingComponent = () => {
  return (
    <div className="loading-container">
      <img src={CompanyLogo} alt="Company Logo" className="logo-loading" />
      <div className="loader"></div>
      <p className="loading-text">
        Just a moment, setting the stage for you...
      </p>
    </div>
  );
};

export default CustomLoadingComponent;
