import "./global.css";
import React, { useEffect, Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import CustomLoadingComponent from "./components/loading-screen";

const Navbar = lazy(() => import("./components/navbar"));
const Footer = lazy(() => import("./components/footer-new"));
const SubMenu = lazy(() => import("./components/sub-menu"));
const Home = lazy(() =>
  import(/* webpackChunkName: "home" */ "./pages/home-page/home-page")
);
const Services = lazy(() => import("./pages/services-page/services-page"));
const ServiceDetail = lazy(() =>
  import("./pages/services-detail-page/services-detail-page")
);
const CareersPage = lazy(() => import("./pages/careers-page/careers-page"));
const ContactUsPage = lazy(() =>
  import("./pages/contact-us-page/contact-us-page")
);
const CompanyPage = lazy(() => import("./pages/company-page/company-page"));
const Projects = lazy(() => import("./pages/projects-page/projects-page"));
const ProjectDetail = lazy(() =>
  import("./pages/projects-detail-page/projects-detail-page")
);

const App = () => {
  return (
    <Router>
      <Suspense fallback={<CustomLoadingComponent />}>
        <Navbar />
        <ScrollToTop />
        <Routing />
        <Footer />
      </Suspense>
    </Router>
  );
};

const ScrollToTop = () => {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    if (hash === "") {
      window.scrollTo(0, 0);
    } else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }, 0);
    }
  }, [pathname, hash]);

  return null;
};

const Routing = () => {
  const location = useLocation();
  return (
    <>
      {location.pathname.includes("/company") && <SubMenu />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/services/:id" element={<ServiceDetail />} />
        <Route path="/careers" element={<CareersPage />} />
        <Route path="/contacts" element={<ContactUsPage />} />
        <Route path="/company" element={<CompanyPage />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/projects/:id" element={<ProjectDetail />} />
      </Routes>
    </>
  );
};

export default App;
